<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									YENİ SERVİS TALEBİ
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SERVİS TALEBİ" entityName="incident"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgiliKisiName" v-model="mainData.ilgiliKisiName" ref="entity_ilgiliKisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="konum" v-model="mainData.konumName" ref="entity_konum"
									label="Konum" entityName="site" nameField="name" :state="true" :required="true"
									@itemSelected="konumSelected = $event"
									@itemCleared="konumSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.konumName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.konumName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="talebiAcan" v-model="mainData.talebiAcanName" ref="entity_talebiAcan"
									label="Talebi Açan" entityName="bm_servispersonel" nameField="bm_name" :state="true" :required="true"
									@itemSelected="talebiAcanSelected = $event" @itemCleared="talebiAcanSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.talebiAcanName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.talebiAcanName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TEZGAH </h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah"
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" :required="true"
									secondNameField="bm_urunid" secondNameFieldType="lookup" parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected" @itemSelected="tezgahSelected = $event"
									@itemCleared="tezgahSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.tezgahName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="garantiDahilmi">Garanti Dahil mi?</label>
								<Dropdown id="garantiDahilmi" v-model="mainData.garantiDahilmi" :options="SM_garantiDahilmi" optionLabel="Value" optionValue="AttributeValue" placeholder="Garanti Dahil mi?" :showClear="true" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SERVİS BİLGİSİ</h5>
					<div class="grid">
						<div class="col-9">
							<div class="field p-fluid">
								<label for="baslik">Başlık <span style="color:red">*</span></label>
								<InputText id="baslik" type="text" v-model="mainData.baslik" class="uppercase" />
								<span v-if="v$.mainData.baslik.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslik.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisTalepTarihi">Servis Talep Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="servisTalepTarihi" v-model="mainData.servisTalepTarihi" autocomplete="off" :showIcon="true" />
								<span v-if="v$.mainData.servisTalepTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.servisTalepTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama</label>
								<Textarea id="aciklama" rows="4" :autoResize="false" v-model="mainData.aciklama" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 112,
			mainData: {},
			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
                },
			]
		}
	},
	async created() {
		
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.mainData["garantiDahilmi"] = false; //Hayır

		const servisPersonel = await this.crmService.getServisPersonelByUserId();
		if (servisPersonel.jsonData != null) {
			if (servisPersonel.jsonData.length > 0) {
				setTimeout(() => {
					this.mainData["talebiAcanId"] = servisPersonel.jsonData[0]["id"];
					this.mainData["talebiAcanName"] = servisPersonel.jsonData[0]["name"];

					this.$refs.entity_talebiAcan.setDefaultData({ "Value": this.mainData["talebiAcanId"], "Name": this.mainData["talebiAcanName"] });

				}, 500);
			}
		}

		if (this.uniteId != null) {
			this.crmService.GetEntityById({
				"entityName": "bm_unite",
				"entityId": this.uniteId,
				"columnNames": ["bm_serino", "bm_uniteid", "bm_kurulummusteriid"]})
			.then(data_unite => {
				if (data_unite.jsonData != null) {
					debugger;

					this.mainData["tezgahId"] = data_unite.jsonData["bm_uniteid"];
					this.mainData["tezgahName"] = data_unite.jsonData["bm_serino"];

					this.mainData["firmaId"] = data_unite.jsonData["bm_kurulummusteriid"];
					this.mainData["firmaName"] = data_unite.jsonData["bm_kurulummusteriidName"];

					this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
					this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });

					this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": this.mainData["firmaId"],
						"columnNames": ["bm_bolge", "primarycontactid", "bm_sahibid"]})
					.then(data => {
						if (data.jsonData != null) {
							let bolgeId = parseInt(data.jsonData["bm_bolgeId"]);
							debugger;
							console.log(this.mainData);
							switch (bolgeId) {
								case 1: //Ankara
									this.mainData["konumId"] = '9A1CEF71-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'ANKARA';
									break;
								case 2: //Bursa
									this.mainData["konumId"] = '7C816F7D-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'BURSA';
									break;
								case 3: //İstanbul
									this.mainData["konumId"] = '27ED7690-8D0B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'MARMARA';
									break;
								case 4: //İzmir
									this.mainData["konumId"] = '9B1CEF71-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'İZMİR';
									break;
								case 930710000: //Konya
									this.mainData["konumId"] = '35FC6ED3-0338-E611-863B-E61F135C9177';
									this.mainData["konumName"] = 'KONYA';
									break;
								case 930710001: //Eskişehir
									this.mainData["konumId"] = 'B85AF616-6F71-E911-86FB-E41F136A9174';
									this.mainData["konumName"] = 'ESKİŞEHİR';
									break;
								default:
									break;
							}

							this.$refs.entity_konum.setDefaultData({ "Value": this.mainData["konumId"], "Name": this.mainData["konumName"] });
						}
					})
					.catch(error => console.log(error));
				}
			})
			.catch(error => console.log(error));
		}
		
	},
	computed: {
		profileData() {
			return this.$store.getters.getProfile;
		},
		uniteId() {
			debugger;
			return this.$route.params.uniteId;
		},
		SM_garantiDahilmi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_garantidahil', 'bool');
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				debugger;
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": newValue.Value,
						"columnNames": ["bm_bolge", "primarycontactid", "bm_sahibid"]})
					.then(data => {
						if (data.jsonData != null) {
							let bolgeId = parseInt(data.jsonData["bm_bolgeId"]);
							debugger;
							console.log(this.mainData);
							switch (bolgeId) {
								case 1: //Ankara
									this.mainData["konumId"] = '9A1CEF71-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'ANKARA';
									break;
								case 2: //Bursa
									this.mainData["konumId"] = '7C816F7D-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'BURSA';
									break;
								case 3: //İstanbul
									this.mainData["konumId"] = '27ED7690-8D0B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'MARMARA';
									break;
								case 4: //İzmir
									this.mainData["konumId"] = '9B1CEF71-F90B-DF11-90BA-00215AA9E6E6';
									this.mainData["konumName"] = 'İZMİR';
									break;
								case 930710000: //Konya
									this.mainData["konumId"] = '35FC6ED3-0338-E611-863B-E61F135C9177';
									this.mainData["konumName"] = 'KONYA';
									break;
								case 930710001: //Eskişehir
									this.mainData["konumId"] = 'B85AF616-6F71-E911-86FB-E41F136A9174';
									this.mainData["konumName"] = 'ESKİŞEHİR';
									break;
								default:
									break;
							}

							this.$refs.entity_konum.setDefaultData({ "Value": this.mainData["konumId"], "Name": this.mainData["konumName"] });
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		konumSelected: {
			get: function () {
				if (this.mainData["konumId"]) {
					return { "Value": this.mainData["konumId"], "Name": this.mainData["konumName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["konumId"] = null;
					this.mainData["konumName"] = null;
				}
				else {
					this.mainData["konumId"] = newValue.Value;
					this.mainData["konumName"] = newValue.Name;
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "bm_unite",
						"entityId": newValue.Value,
						"columnNames": ["bm_kurulummusteriid"]})
					.then(data => {
						if (data.jsonData != null) {
							let bm_kurulummusteriid = data.jsonData["bm_kurulummusteriid"];
							let bm_kurulummusteriidName = data.jsonData["bm_kurulummusteriidName"];
							
							this.mainData["firmaId"] = bm_kurulummusteriid;
							this.mainData["firmaIdName"] = bm_kurulummusteriidName;

							let firmaItem = { "Value": bm_kurulummusteriid, "Name": bm_kurulummusteriidName };
							this.firmaSelected = firmaItem;
							this.$refs.entity_firma.setDefaultData(firmaItem);
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		tezgahModelSelected: {
			get: function () {
				if (this.mainData["tezgahModelId"]) {
					return { "Value": this.mainData["tezgahModelId"], "Name": this.mainData["tezgahModelName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahModelId"] = null;
					this.mainData["tezgahModelName"] = null;
				}
				else {
					this.mainData["tezgahModelId"] = newValue.Value;
					this.mainData["tezgahModelName"] = newValue.Name;
				}
			}
		},
		talebiAcanSelected: {
			get: function () {
				if (this.mainData["talebiAcanId"]) {
					return { "Value": this.mainData["talebiAcanId"], "Name": this.mainData["talebiAcanName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["talebiAcanId"] = null;
					this.mainData["talebiAcanName"] = null;
				}
				else {
					this.mainData["talebiAcanId"] = newValue.Value;
					this.mainData["talebiAcanName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getServisTalebiCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}

							setTimeout(() => {
								this.$router.push({ name: 'incident', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		formatNumber(value) {
			debugger;
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					break;
			}
			return paraBirimi;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Servis Talepleri');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaName: {
					required: helpers.withMessage('Firma adı alanı dolu olmalıdır!', required),
				},
				konumName: {
					required: helpers.withMessage('Konum alanı dolu olmalıdır!', required),
				},
				tezgahName: {
					required: helpers.withMessage('Tezgah alanı dolu olmalıdır!', required),
				},
				baslik: {
					required: helpers.withMessage('Başlık alanı dolu olmalıdır!', required),
				},
				servisTalepTarihi: {
					required: helpers.withMessage('Servis Talep Tarihi alanı dolu olmalıdır!', required),
				},
				talebiAcanName: {
					required: helpers.withMessage('Talebi Açan alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
</style>
